import { Slider, Switch } from "antd";
import { useEffect, useRef, useState } from "react";

const COLOR_BACKGROUND = '#262626';
const COLOR_GRID = 'white';
const COLOR_LINE_STEP = '#464646'
const COLOR_LEGEND = '#fff';

const HEIGHT = 500;

const SIZE_PADDING_TOP = 15;
const SIZE_PADDING_BOTTOM = 30;
const SIZE_PADDING_LEFT = 10;
const SIZE_PADDING_RIGHT = 50;
const JERSEY_RADIUS = 12;

/*
^           +---------------------------------------------------------------------------------------------------+
|           |                            ^                                                 Jersey size          |
|           |                            | Padding top                                   <--->                  |
|           |                            v                                                                      |
|           |        _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ X kmh            |
|           |                                                                             / \                   |
|           |                                /-------------------------------------------|   |                  |
|           |        -----\                 /                                             \ /                   | 
|           |              ----______------/                                               -                    |
|           |                                                                               <------------------>|
|           |                                                                                      Padding right|
| Height    |                                                                               <->                 |
|           |                                                                                Jersey radius      |
|           |        _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ X kmh            |
|           |                                                                                                   |
|           |<------>                                                                                           |
|           |   Padding left                                                                                    |
|           |                                                                                                   |
|           |                                                                                                   |
|           |        _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ X kmh            |
|           |                            ^                                                                      |
|           |                            | Padding bottom                                                       |
|           |                            v                                                                      |
v           +---------------------------------------------------------------------------------------------------+
*/

const COEFS_X = [0.5, 1, 2, 5, 10, 20];

function reformatColor(color) {
    if (!color) {
        return '#888888';
    }
    if (color.startsWith('#')) {
        return color;
    }
    if (color.length === 6 && color.match(/^[a-f0-9A-F]*$/)) {
        return '#' + color;
    }
    return color
}

function drawingGraph(ctx, width, height, serie, coefY, coefX, valueKey, key, color, min, max) {
    let x = SIZE_PADDING_LEFT;
    let yPrevious = null;
    ctx.beginPath();
    ctx.strokeStyle = color ? reformatColor(color.fill) : 'red';
    serie
        .map(speed => speed[valueKey])
        .forEach(vit => {
            if (vit > 0) {
                const y = height - SIZE_PADDING_BOTTOM - ((vit - min) * coefY);
                if (!yPrevious) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }
                yPrevious = y;
            } else {
                yPrevious = null;
            }
            x+= coefX;
        })
    ctx.stroke();

    const startOrLast = serie
        .map(speed => speed[valueKey])
        .map((vit, index) => ({index, vit}))
        .filter(item => item.vit > 0)
        .pop(); // shift = end of the left // pop = end of the right
    if (startOrLast) {
        const x = SIZE_PADDING_LEFT + (startOrLast.index * coefX);
        const y = height - SIZE_PADDING_BOTTOM - ((startOrLast.vit - min) * coefY);

        ctx.beginPath();
        ctx.fillStyle = color ? reformatColor(color.fill) : 'red';
        ctx.arc(x, y, JERSEY_RADIUS, 0, 2*Math.PI);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = COLOR_BACKGROUND;
        ctx.font = `${JERSEY_RADIUS}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
        ctx.fillText(key, x - (JERSEY_RADIUS / 4 * key.length), y + (1 * JERSEY_RADIUS / 3));
    }
}

function drawingGraphs(ctx, width, height, richSpeeds, min, max, nbPoint, coefY, coefX, valueKey) {
    Object.keys(richSpeeds)
        .map(key => richSpeeds[key])
        .filter(richSpeed => richSpeed.displayed)
        .forEach(richSpeed => {
            const list = richSpeed.speeds.filter((v, index, all) => index > all.length - nbPoint); // Only the nbPoint last
            drawingGraph(ctx, width, height, list, coefY, coefX, valueKey, richSpeed.nump, richSpeed.color, min, max)
        })
}

function drawingSpeedConstants(ctx, width, height, min, max, coefY, coefX, gridStep) {
    const maxKMpH = max * 3.6;
    const minKMpH = min * 3.6;
    for (let speedKMpH = minKMpH; speedKMpH <= maxKMpH; speedKMpH+= gridStep) {
        const speedKMpHStr = '' + (Math.round(speedKMpH * 100) / 100);
        const y = height - SIZE_PADDING_BOTTOM - ((speedKMpH - minKMpH ) * coefY / 3.6);
        ctx.beginPath();
        ctx.strokeStyle = COLOR_LINE_STEP;
        ctx.moveTo(SIZE_PADDING_LEFT, y);
        ctx.lineTo(width - SIZE_PADDING_RIGHT, y);
        ctx.stroke();
        ctx.fillStyle = COLOR_GRID;
        ctx.font = "10px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif";
        ctx.fillText(`${speedKMpHStr} km/h`, width - SIZE_PADDING_RIGHT + JERSEY_RADIUS, y - 5);
    }
}

function drawChart(ctx, width, height, richSpeeds, coefX, valueKey) {

    const nbPoint = (width - SIZE_PADDING_LEFT - SIZE_PADDING_RIGHT) / coefX;

    ctx.fillStyle = COLOR_BACKGROUND;
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fill();

    const max = Object.keys(richSpeeds)
        .map(key => richSpeeds[key].speeds)
        .map(speeds => speeds.filter((speed, index, all) => index > (all.length - nbPoint)))
        .flat()
        .reduce((max, speed) => Math.max(max, speed[valueKey]), 0)
    const min =  Object.keys(richSpeeds)
        .map(key => richSpeeds[key].speeds)
        .map(series => series.filter((speed, index, all) => index > (all.length - nbPoint)))
        .map(series => series.filter(speed => speed[valueKey] > 0))
        .flat()
        .reduce((min, speed) => min === 0 ? speed[valueKey] : Math.min(min, speed[valueKey]), 0)
    if (max !== 0) {
        const speedWindow = (max - min)
        const gridStep = (speedWindow * 3.6) > 10 ? 5
            : (speedWindow * 3.6) > 2 ? 1
            : 0.1;

        const maxScaled = Math.ceil( max * 3.6 / gridStep) * gridStep / 3.6;
        const minScaled = Math.floor( min * 3.6 / gridStep) * gridStep / 3.6;
        const coefY = (height - SIZE_PADDING_TOP - SIZE_PADDING_BOTTOM) / (maxScaled - minScaled);

        drawingSpeedConstants(ctx, width, height, minScaled, maxScaled, coefY, coefX, gridStep);
        drawingGraphs(ctx, width, height, richSpeeds, minScaled, maxScaled, nbPoint, coefY, coefX, valueKey);
    }
}

function drawWaiting(ctx, width, height) {
    ctx.fillStyle = COLOR_BACKGROUND;
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fill();

    ctx.fillStyle = COLOR_LEGEND;
    ctx.font = "48px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif";
    ctx.fillText("Waiting data", 10, 50);
}

export function RaceSpectatorChart({waitingData, speeds, colors}, _ref) {

    const [indexCoefX, setIndexCoefX] = useState(2);
    const [width, setWidth] = useState(300);
    const [height/*, setHeight*/] = useState(HEIGHT);
    const [richSpeeds, setRichSpeeds] = useState({});

    const refContainer = useRef(null);
    const refCanvas = useRef(null);

    const valueKey = 'smooth_2'; // instant / smooth_2 / smooth_4 / smooth_10 / smooth_30 / smooth_60 / smooth_120 / smooth_global

    useEffect(() => {
        setWidth(refContainer.current.clientWidth);
    }, [speeds]);

    useEffect(() => {
        Object.keys(speeds).forEach(nump => {
            richSpeeds[nump] = {
                speeds: speeds[nump],
                nump,
                color: colors[nump],
                displayed: richSpeeds[nump]?.displayed !== undefined ? richSpeeds[nump].displayed : true,
            };
        })
        setRichSpeeds({...richSpeeds});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speeds, colors]);


    useEffect(() => {
        const canvas = refCanvas.current;
        const context = canvas.getContext('2d');
        if (waitingData) {
            drawWaiting(context, width, height);
        } else {
            drawChart(context, width, height, richSpeeds, COEFS_X[indexCoefX], valueKey);
        }
    }, [waitingData, width, height, richSpeeds, indexCoefX]);

    return (
        <div className="race_spectator_chart">
            <h1 className="race_spectator_subtitle">
                Chart
                <div className="race_spectator_chart_zoom_setting">
                    <Slider
                        value={indexCoefX}
                        min={0}
                        max={COEFS_X.length - 1}
                        step={1}
                        onChange={value => setIndexCoefX(value)}
                        />
                </div>
                <div className="race_spectator_chart_competitors_filtering">
                    {
                        Object.keys(richSpeeds).map(nump => (
                            <Switch
                                key={nump}
                                checkedChildren={nump}
                                unCheckedChildren={nump}
                                value={1}
                                onChange={value => {
                                    setRichSpeeds({
                                        ...richSpeeds,
                                        [nump]: {
                                            ...richSpeeds[nump],
                                            displayed: value,
                                        },
                                    })
                                }}
                                style={{
                                    // backgroundColor: richSpeeds[nump].displayed ? richSpeeds[nump].color : '#262626',
                                    backgroundColor: richSpeeds[nump].displayed ? 'green' : '#262626',
                                }}
                                checked={richSpeeds[nump].displayed}
                                />
                        ))
                    }
                </div>

            </h1>
            <div className="race_spectator_chart_block" ref={refContainer} >
                <canvas ref={refCanvas} id="chart_canvas" width={width} height={height}/>
            </div>
        </div>
    )
}





