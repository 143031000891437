import React, { useEffect, useState } from 'react'
import {
    GoogleMap,
    LoadScript,
    Marker,
} from "@react-google-maps/api";

const API_KEY = 'AIzaSyAYaZgSBTuYuncSiRn2VcKKIquA8GXUJ-k';

const MAP_CENTER = {
    lat: -34.397,
    lng: 150.644,
};

// MAP containerStyle 
const MAP_STYLE = {
    width: "400px",
    height: "300px",
};

const MAP_OPTIONS = {
    streetViewControl: false,
    mapTypeId: 'satellite',
    scaleControl: true,
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 6.0, // value for LEFT_BOTTOM
    },
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 3,
    maxZoom: 20,
    mapTypeControl: false,
    zoomControl: true,
    clickableIcons: false,
    disableDefaultUI: true
};

const MAP_ZOOM = 18;

function unformatColor(color) {
    if (!color) {
        return '888888';
    }
    if (color.startsWith('#')) {
        return color.replace('#', '');
    }
    if (color.length === 6 && color.match(/^[a-f0-9A-F]*$/)) {
        return color;
    }
    return '888888'
}

function WaitingDataMap() {
    return (
        <div className='race_spectator_performances_map_waiting'>
            Waiting data
        </div>
    );
}

function Map({waitingData, dataFull, config}, _ref) {

    // const [map, setMap] = useState();
    const [center, setCenter] = useState(MAP_CENTER);
    // const [zoom, setZoom] = useState(MAP_ZOOM);
    const zoom = MAP_ZOOM;
    // const [options, setOptions] = useState(MAP_OPTIONS);
    const options = MAP_OPTIONS;
    const [positions, setPositions] = useState([]);

    const [marginTop, setMarginTop] = useState(0);
    const [width, setWidth] = useState(MAP_STYLE.width);
    const [height, setHeight] = useState(MAP_STYLE.height);

    // const onMapLoad = map => setMap(map);
    const onMapLoad = () => {};

    useEffect(() => {
        const _positions = 
            Object.keys(dataFull)
                .map(key => dataFull[key])
                .map(competitor => ({
                    lat: competitor.Pos.lat,
                    lng: competitor.Pos.long,
                    NumP: competitor.NumP,
                    border: competitor.border,
                    color: competitor.color,
                    font: competitor.font,
                    PosC: competitor.PosC,
                }))
                .filter(position => !isNaN(position.lat))
                .filter(position => !isNaN(position.lng))
                .filter(position => position.lat > 0)
                .filter(position => position.lng > 0);
        setPositions(_positions);
        const topPosition = _positions
            .sort((p1, p2) => 
                p1.PosC === 0     ? -1 : // 0   / x   => x est le mieux
                p2.PosC === 0     ?  1 : // x   / 0   => x est le mieux
                p1.PosC < p2.PosC ?  1 : // x   / x+y => x est le mieux
                p1.PosC > p2.PosC ? -1 : // x+y / x   => x est le mieux
                0                        // x   / x   => c'est pareil
            )
            .filter(x => true) // to copy and do pop without removing
            .pop();
        if (topPosition) {
            setCenter({
                lat: topPosition.lat,
                lng: topPosition.lng,
            });
        } else if (Object.keys(dataFull).length > 0) {
            console.warn('Enable to calculate topPosition of (P)', _positions)
            console.warn('Enable to calculate topPosition of (D)', dataFull)
        }
    }, [dataFull]);
    
    useEffect(() => {
        if (config && config.mode === 'inline') {
            setWidth(config.maxWidth);
            setHeight(config.maxHeight);
            setMarginTop(0)
        } else if (config && config.mode === 'under') {
            setWidth(config.maxWidth);
            setHeight(400);
            setMarginTop(10)
        } else if (config) {
            console.log('Error', config)
        } else {
            setWidth(MAP_STYLE.width);
            setHeight(MAP_STYLE.height);
        }
    }, [config]);

    return (
        <div style={{marginTop}}>
            { waitingData 
                ?   <WaitingDataMap />
                :   <LoadScript googleMapsApiKey={API_KEY} >
                        <GoogleMap
                            id='pilotageMap'
                            mapContainerStyle={{
                                width,
                                height,
                            }}
                            zoom={zoom}
                            center={center}
                            onLoad={map => onMapLoad(map)}
                            options={options}>
                            {
                                positions.map(position => 
                                    <span key={position.NumP}>
                                        <Marker
                                            position={{ lat: position.lat, lng: position.lng }}
                                            icon={`${window.APP_SERVER_URL}/api/v2/icon/${unformatColor(position.color)}/${position.NumP}`}
                                            />
                                    </span>
                                    )
                            }
                        </GoogleMap>
                    </LoadScript>
            }
        </div>
    )
}

export const RaceSpectatorPerformancesMap = React.forwardRef(Map)
