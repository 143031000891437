import { Button, DatePicker } from "antd";
import React, {useState, useEffect} from "react";

const { RangePicker } = DatePicker;

export default function UserFormMultiDate({selections: objSelections, sources, onApply}) {

    const [listSelections, setListSelections] = useState([]);
    const [helpMessage, setHelpMessage] = useState(null);
    const [start, setStart] = useState(null);
    const [stop, setStop] = useState(null);

    useEffect(() => {
        const list = Object.keys(objSelections).map(key => {
            const source = sources.find(s => s.uuid === key);
            const label = source?.label;
            const uuid = source?.uuid;
            const list = Object.keys(objSelections[key]).filter(right => objSelections[key][right] === true);
            return ({
                uuid,
                label,
                list,
            })
        }).filter(item => item.list.length > 0);
        setListSelections(list)
        if (list.length === 0) {
            setHelpMessage('Sélectionnez des cellules pour une mise à jour en masse')
        } else {
            setHelpMessage(null)
        }
    }, [objSelections, sources])

    function changeRange(mStart, mStop) {
        setStart(mStart.format('YYYY-MM-DD'));
        setStop(mStop.format('YYYY-MM-DD'));
    }

    return (
        <div className="user-form-multidate">
            <div className="user-form-multidate-selections">
                {
                    listSelections.map(item => (
                        <div key={item.uuid}>
                            {item.label} : {item.list.join(', ')}
                        </div>
                    ))
                }
                {
                    helpMessage
                }
            </div>
            <RangePicker onChange={([mStart, mStop]) => changeRange(mStart, mStop)} disabled={listSelections.length===0}/>
            <Button onClick={() => onApply(listSelections, start, stop)} disabled={listSelections.length===0}>
                Appliquer
            </Button>
        </div>
    );
}
