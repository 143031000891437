import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import UserService from "../services/user.service";
import SourceService from "../services/source.service";
import { Button, message } from "antd";
import UserFormSources from "../components/UserFormSources";

import './privateUserForm.css';
import UserFormData from "../components/UserFormData";
import UserFormMultiDate from "../components/UserFormMultiDate";

const RIGHTS = ['Classic', 'LiveFull', 'LiveSectional', 'PostFullRaw', 'PostFullMec', 'PostSectionalRaw', 'PostSectionalMec', 'Web'];

export default function PrivateUserForm() {

    const { uuid } = useParams();
    const history = useHistory();

    const [title, setTitle] = useState();
    const [userData, setUserData] = useState({});
    const [userRights, setUserRights] = useState([]);
    const [sources, setSources] = useState([]);
    const [selections, setSelections] = useState({});

    useEffect(() => {
        SourceService.list()
            .then(sources => {
                setSources(sources);
            }).catch(error => {
                console.log(error)
                message.error('Une erreur est survenue')
            })
    }, []);

    useEffect(() => {
        if (uuid) {
            UserService.loadByUUID(uuid)
                .then(user => {
                    setUserData(user)
                    setTitle('Utilisateur #' + user.uuid);
                }).catch(error => {
                    console.log(error);
                    message.error('Une erreur est survenue lors du chargement de l\'utilisateur');
                })
            UserService.getRightsByUUID(uuid)
                .then(rights => setUserRights(rights))
                .catch(error => {
                    console.log(error);
                    message.error('Une erreur est survenue lors du chargement de l\'utilisateur');
                })
        } else {
            setTitle('Nouvel utilisateur');
            setUserData({
                isAdmin: false,
                hasSpectatorArchives: false,
            })
            setUserRights([])
        }
    }, [uuid])

    const changeUserData = (data) => {
        setUserData({ ...userData, ...data});
    }

    const onChangeRange = (sourceUuid, rightKey, start, stop) => {
        let right = userRights.find(right => right.source === sourceUuid);
        if (!right) {
            right = {
                source: sourceUuid,
            };
            RIGHTS.forEach(key => {
                right[`activation${key}Start`] = null;
                right[`activation${key}Stop`] = null;
            })
            userRights.push(right);
        }
        right[`activation${rightKey}Start`] = start;
        right[`activation${rightKey}Stop`] = stop;
        setUserRights([...userRights]);
    }

    const onChangeRanges = (list, start, stop) => {
        list.forEach(item => {
            item.list.forEach(rightKey => {
                onChangeRange(item.uuid, rightKey, start, stop)
            })
        })
    }

    const onChangeSelection = (sourceUuid, rightKey, selected) => {
        const newSelections = {
            ...selections
        };
        newSelections[sourceUuid] = newSelections[sourceUuid] || {};
        newSelections[sourceUuid][rightKey] = selected;
        setSelections(newSelections)
    }

    const onCancel = () => {
        history.push("/users");
    }

    const onFinish = () => {
        UserService
            .saveUserWithRights({
                ...userData,
                rights: userRights,
            })
            .then(saved => {
                if (saved) {
                    message.success('Enregistrement effectué');
                    history.push(`/user/${saved.uuid}`)
                } else {
                    message.error('Une erreur est survenue lors de l\'enregistrement');
                }
            });
    }

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper user-page">
            <div className="header">
                <span className="title">{title}</span>
            </div>
            <div className="user-form">
                <UserFormData user={userData} onChangeUserData={data => changeUserData(data)} />
                <UserFormMultiDate
                    selections={selections}
                    sources={sources}
                    onApply={(list, start, stop) => onChangeRanges(list, start, stop)}
                    />
                <UserFormSources
                    rights={userRights}
                    sources={sources}
                    selections={selections}
                    onChangeRange={(sourceUuid, rightKey, start, stop) => onChangeRange(sourceUuid, rightKey, start, stop)}
                    onChangeSelection={(sourceUuid, rightKey, selected) => onChangeSelection(sourceUuid, rightKey, selected)}
                    />
                <div className="user-form-actions">
                    <div className="user-form-action">
                        <Button type="primary" htmlType="submit" onClick={() => onCancel()} className="user-form-button">
                            Retour
                        </Button>
                    </div>
                    <div className="user-form-action">
                        <Button type="primary" htmlType="submit" onClick={() => onFinish()} className="user-form-button">
                            Enregistrer
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}