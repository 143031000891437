import axios from "axios";
import authHeader from "./auth-header";

const RIGHTS = ['Classic', 'LiveFull', 'LiveSectional', 'PostFullRaw', 'PostFullMec', 'PostSectionalRaw', 'PostSectionalMec', 'Web'];

// list -------------------------------------------------------------
const list = async (filters) => {
  return await axios({
    url:  `${window.APP_SERVER_URL}/api/user/list`,
    method: 'post',
    headers: authHeader(),
    data: filters
  })
}

// load -------------------------------------------------------------
const load = async (id) => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/user/load`,
    method: 'post',
    headers: authHeader(),
    data: id
  })
}

const loadByUUID = async (uuid) => {
  return axios({
    url: `${window.APP_SERVER_URL}/api/v2/user/${uuid}`,
    method: 'get',
    headers: authHeader(),
  }).then(response => response.data)
}

const getRightsByUUID = async (uuid) => {
  return axios({
    url: `${window.APP_SERVER_URL}/api/v2/user/${uuid}/rights`,
    method: 'get',
    headers: authHeader(),
  }).then(response => response.data)
}

// save -------------------------------------------------------------
const save = async (data) => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/user/save`,
    method: 'post',
    headers: authHeader(),
    data: data
  })
}

async function createUser(user) {
  return Promise.resolve()
    .then(() => ({
      username: user.username,
      email: user.email,
      password: user.password,
      confirm: user.confirm,
      isAdmin: user.isAdmin,
      hasSpectatorArchives: user.hasSpectatorArchives,
    }))
    .then(item => axios({
      url: `${window.APP_SERVER_URL}/api/v2/user`,
      method: 'post',
      headers: authHeader(),
      data: item,
    }))
    .then(response => response.data.value);
  }

async function createUserWithRights(user) {
  return Promise.resolve()
    .then(() => createUser(user))
    .then(created => updateRights(created.uuid, user.rights).then(() => created))
    .catch(error => {
      console.log(error)
      return false
    });
}

async function updateUser(user) {
  return Promise.resolve()
    .then(() => ({
      username: user.username,
      email: user.email,
      password: user.password ? user.password : null,
      confirm: user.password ? user.confirm : null,
      active: true,
      isAdmin: user.isAdmin,
      hasSpectatorArchives: user.hasSpectatorArchives,
    }))
    .then(item => axios({
      url: `${window.APP_SERVER_URL}/api/v2/user/${user.uuid}`,
      method: 'put',
      headers: authHeader(),
      data: item,
    }))
    .then(response => response.data.value);
}

async function updateRight(item) {
  return Promise.resolve()
    .then(() => axios({
      url: `${window.APP_SERVER_URL}/api/v2/user/${item.userUUID}/source/${item.sourceUUID}/right`,
      method: 'put',
      headers: authHeader(),
      data: item,
    }))
    .then(() => true)
    .catch(() => false)
}

async function updateRights(userUUID, rights) {
  return Promise.resolve()
    .then(() => formatRights(userUUID, rights))
    .then(formated => formated.reduce(
        (previous, item) => previous
          .then(successPrevious => updateRight(item)
            .then(successItem => successPrevious && successItem)
          ),
        Promise.resolve(true)
      )
    )
}

function formatRight(userUUID, right) {
  const formated = {
    userUUID: userUUID,
    sourceUUID: right.source,
  }
  RIGHTS.forEach(key => {
    formated[`activation${key}Start`] = right[`activation${key}Start`] ? right[`activation${key}Start`] : null;
    formated[`activation${key}Stop`] = right[`activation${key}Stop`] ? right[`activation${key}Stop`] : null;
  })
  return formated;
}

function formatRights(userUUID, rights) {
  return rights.map(right => formatRight(userUUID, right))
}

async function updateUserWithRights (user) {
  return Promise.resolve()
    .then(() => updateUser(user))
    .then(updated => updateRights(updated.uuid, user.rights).then(() => updated))
    .catch(error => {
      console.log(error)
      return false
    });
}

const saveUserWithRights = async (user) => {
  return Promise.resolve()
    .then(() => user.uuid ? updateUserWithRights(user) : createUserWithRights(user))
}

// del --------------------------------------------------------------
const del = async (id) => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/user/del`,
    method: 'post',
    headers: authHeader(),
    data: {
      id: id
    }
  })
}

// default export ---------------------------------------------------
const user = {
  list,
  load,
  loadByUUID,
  save,
  saveUserWithRights,
  getRightsByUUID,
  del
};

export default user;