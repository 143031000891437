import axios from "axios";
import authHeader from "./auth-header";

// status -------------------------------------------------------------
const status = async () => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/live/status`,
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error);
  })
}

// start -------------------------------------------------------------
const start = async () => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/live/start`,
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error);
  })
}

// stop -------------------------------------------------------------
const stop = async () => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/live/stop`,
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error);
  })
}

// tail -------------------------------------------------------------
const tail = async () => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/live/tail`,
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error);
  })
}

// all -------------------------------------------------------------
const all = async () => {
  return await axios({
    url: `${window.APP_SERVER_URL}/api/live/all`,
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error);
  })
}

// connected -------------------------------------------------------------
const connected = async () => {
  return await axios({
    url:`${window.APP_SERVER_URL}/api/live/connected`,
    method: 'post',
    headers: authHeader(),
  }).catch(function (error) {
    console.log(error);
  })
}

// meeting_list -----------------------------------------------------
const meeting_list = async (data) => {
  return Promise.resolve()
    .then(() => axios.get(`${window.APP_SERVER_URL}/api/v2/${data.date}/cards`, {headers: authHeader()}))
    .then(response => response.data)
    .then(list => list.map(race => ({
      numr: race.meeting,
      numc: race.number,
      libc: race.label,
      hippodrome: race.venue.code,
      heurec: race.hours,
      disc: race.type,
      dist: race.distance,
      ndpart: race.starters,
      source: race.source?.code,
    })))
}

// runners_details -----------------------------------------------------
const runners_details = async ({date, venue, numc}) => {
  return Promise.resolve()
    .then(() => axios.get(`${window.APP_SERVER_URL}/api/v2/${date}/venue/${venue}/race/${numc}/card`, {headers: authHeader()}))
    .then(response => response.data)
    .then(race => race.competitors.map(competitor => ({
      numr: race.meeting,
      numc: race.number,
      disc: race.type,
      dist: race.distance,
      ndpart: race.competitors.length,
      num: competitor.number,
      nomc: competitor.name,
      jock: competitor.driver,
    })))
}

// default export ---------------------------------------------------
const live = {
  status,
  start,
  stop,
  tail,
  all,
  connected,
  meeting_list,
  runners_details,
}

export default live;