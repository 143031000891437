import React, {useState, useEffect} from "react";

import { DatePicker, Modal, Switch } from "antd";

const { RangePicker } = DatePicker;

function isActual(start, stop) {
    if (!start) {
        return false;
    }
    if (!stop) {
        return false;
    }
    const today = new Date();
    const stringified = ''
        + today.getFullYear()
        + '-'
        + (today.getMonth() < 9 ? '0' : '')
        + (today.getMonth() + 1)
        + '-'
        + (today.getDate() < 10 ? '0' : '')
        + (today.getDate());
    return stringified >= start
        && stringified <= stop;
}

export default function UserFormRight({item, enabled, selection, onChangeRange, onChangeSelection}) {

    const [isModalOpen, setOpenModal] = useState(false);
    const [isActivate, setActivate] = useState(false);
    const [start, setStart] = useState(null);
    const [stop, setStop] = useState(null);
    const [newStartYear, setNewStartYear] = useState(null);
    const [newStartMonth, setNewStartMonth] = useState(null);
    const [newStartDate, setNewStartDate] = useState(null);
    const [newStopYear, setNewStopYear] = useState(null);
    const [newStopMonth, setNewStopMonth] = useState(null);
    const [newStopDate, setNewStopDate] = useState(null);

    const [classes, setClasses] = useState(['user-source-input']);

    useEffect(() => {
        setActivate(!!item.start);
        setStart(item.start);
        setStop(item.stop);
        if (!!item.start && !!item.stop) {
            setNewStartYear(Number.parseInt(item.start.substring(0,4)));
            setNewStartMonth(Number.parseInt(item.start.substring(5,7)));
            setNewStartDate(Number.parseInt(item.start.substring(8,10)));
            setNewStopYear(Number.parseInt(item.stop.substring(0,4)));
            setNewStopMonth(Number.parseInt(item.stop.substring(5,7)));
            setNewStopDate(Number.parseInt(item.stop.substring(8,10)));
        } else {
            setNewStartYear(null);
            setNewStartMonth(null);
            setNewStartDate(null);
            setNewStopYear(null);
            setNewStopMonth(null);
            setNewStopDate(null);
        }
    }, [item.start, item.stop])

    useEffect(() => {
        const classes = ['user-source-input'];
        if (enabled && isActivate) {
                classes.push('activate')
        } else if (enabled && !isActivate) {
            classes.push('unactivate')
        } else if (!enabled) {
            classes.push('disabled')
        }
        if (isActual(start, stop)) {
            classes.push('actual-period')
        } else {
            classes.push('other-period')
        }
        if (selection) {
            classes.push('selected')
        }
        setClasses(classes)
    }, [enabled, isActivate, start, stop, selection]);

    const changeActivate = () => {
        if (isActivate) {
            onChangeRange(null, null);
            setActivate(false);
        } else {
            setOpenModal(true);
        }
    }

    const handleOk = () => {
        const newStart = newStartYear
            + '-'
            + (newStartMonth < 10 ? '0' : '')
            + newStartMonth
            + '-'
            + (newStartDate < 10 ? '0' : '')
            + newStartDate;
        const newStop = newStopYear
            + '-'
            + (newStopMonth < 10 ? '0' : '')
            + newStopMonth
            + '-'
            + (newStopDate < 10 ? '0' : '')
            + newStopDate;
        console.log('Change (1) to', newStart)
        onChangeRange(newStart, newStop);
        setStart(newStart);
        setStop(newStop);
        setActivate(true);
        setOpenModal(false);
    }

    function changeRange(mStart, mStop) {
        setNewStartDate(mStart.date())
        setNewStartMonth(mStart.month() + 1)
        setNewStartYear(mStart.year())
        setNewStopDate(mStop.date())
        setNewStopMonth(mStop.month() + 1)
        setNewStopYear(mStop.year())
    }

    const handleCancel = () => {
        setOpenModal(false);
    }

    return (
        <td className={classes.join(' ')} onClick={() => enabled ? onChangeSelection(!selection) : null}>
            <div className="user-source-switch">
                Activé :
                <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    <Switch checked={isActivate} onChange={() => changeActivate()} disabled={!enabled} />
                </span>
            </div>
            { enabled ?
                <>
                    <div className="user-source-date-start">
                        {isActivate ?  (<>du {start}</>) : null }
                    </div>
                    <div className="user-source-date-stop">
                        {isActivate ?  (<>au {stop}</>) : null }
                    </div>
                    <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                        <Modal
                            title="Choix de la période d'activation"
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            className="modal-period-choice"
                            >
                            <RangePicker
                                ranges={['2024-12-01', '2028-01-31']}
                                onChange={([mStart, mStop]) => changeRange(mStart, mStop)}
                                />
                        </Modal>
                    </span>
                </>
                : null
            }
        </td>
    );
}
