import React, { useEffect, useState } from "react";
import UserFormRight from "./UserFormRight";

const RIGHTS = ['Classic', 'LiveFull', 'LiveSectional', 'PostFullRaw', 'PostFullMec', 'PostSectionalRaw', 'PostSectionalMec', 'Web'];

export default function UserFormSource({source, right, selections, onChangeRange, onChangeSelection}) {

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(RIGHTS.map(key => ({
            endabledKey: `enabled${key}`,
            start: right[`activation${key}Start`],
            stop: right[`activation${key}Stop`],
            key
        })))
    }, [right])

    function changeRange(rightKey, start, stop) {
        if (onChangeRange) {
            onChangeRange(rightKey, start, stop)
        }
    }

    return (
        <tr key={source.uuid} className="user-source-form">
            <td className="user-source-label">
                {source.label}
            </td>
            {list.map(item => (
                <UserFormRight
                    key={item.key}
                    sourceUUID={source.uuid}
                    item={item}
                    enabled={source[item.endabledKey]}
                    selection={selections[item.key] || false}
                    onChangeRange={(start, stop) => changeRange(item.key, start, stop)}
                    onChangeSelection={(selected) => onChangeSelection(item.key, selected)}
                    />
            ))}
        </tr>
    )

}
