import axios from "axios";
import authHeader from "./auth-header";

const getStatus = async () => {
  return axios({
    url: `${window.APP_SERVER_URL}/api/v2/app/status`,
    method: 'get',
    headers: authHeader(),
  })
  .then(response => response.data)

}

// default export ---------------------------------------------------
const VersionService = {
  getStatus,
};

export default VersionService;