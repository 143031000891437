import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import VersionService from "../services/version.service";

const { Header, Content, Footer } = Layout;

export default function PublicLayout(props) {

  const [version, setVersion] = useState('-.-.-')

  useEffect(() => {
    VersionService
      .getStatus()
      .then(status => {
        setVersion(status.version)
      })
  }, [])  

  return (
    <Layout className="public_layout">
      <Header className="public_layout_header">
        <img src={`/images/website/logo-mccloyd-data.svg`} alt="McLloyd" onClick={() => document.location.href = "https://data.mclloyd.com"} />
        <Button type="primary" className="login-header-button" href="https://data.mclloyd.com">Discover</Button>
      </Header>
      <Content className="public_layout_content">{props.children}</Content>
      <Footer className="public_layout_footer"><p>Version {`${version}`}</p></Footer>
    </Layout>    
  )
}