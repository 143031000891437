import React, {useState, useEffect} from "react";
import userService from "../services/user.service";
import roleService from "../services/role.service";
import { Button, Table, Tag, Tooltip, Modal, Typography, message, Select } from "antd";
import { 
  EditOutlined, 
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CrownOutlined,
} from '@ant-design/icons';
import { useHistory } from "react-router-dom";

const { confirm } = Modal;
const { Text } = Typography;
const { Option } = Select;

function UserItemName({record}) {
    return (
        <>
            <p>{record.username}</p>
            <p>{record.email}</p>
        </>
    );
}

function UserItemRoles({roles}) {
    return roles ? roles.map(role => <Tag key={role.name}>{role.name}</Tag>) : null;
}

function UserItemActivation({actif}) {
    return actif ? <CheckCircleOutlined className="record_active" /> : <CloseCircleOutlined className="record_disabled" />;
}

function UserItemIsAdmin({isAdmin}) {
    return isAdmin ? <CrownOutlined className="record_admin" /> : '-';
}

function UserItemDate({date}) {
    return date ? (
        <p>{new Date(date).toISOString().slice(0, 10)}</p>
    ) : <p> - </p>;
}

function UserItemActions({onUpdate, onDelete}) {
    return (
        <>
            <Tooltip title="Modifier">
                <Button onClick={event => onUpdate ? onUpdate(event) : null} shape="circle" icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title="Supprimer">
                <Button onClick={event => onDelete ? onDelete(event) : null} shape="circle" icon={<DeleteOutlined />} />
            </Tooltip>
        </>
    );
}

// Search_filters ------------------------------------------------
function SearchFilters({label, placeholder, options, onchange}) {
    return (
        <div key={label} className="search_filters_elems">
            <div key={`${label}_title`} className="search_filters_title_div">
                <Text>
                    {label}
                </Text>  
            </div>
            <div key={`${label}_input`} className="search_filters_select_div">
                <Select 
                    mode="multiple" 
                    style={{ width: '95%' }} 
                    placeholder={placeholder}
                    onChange={onchange}
                    optionFilterProp="children"
                    allowClear
                    dropdownMatchSelectWidth={false}
                    disabled={false && !options.length}
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    }
                    >
                    {options}
                </Select>
            </div>
        </div>
    );
}

export default function PrivateUserList() {
    const history = useHistory();

    const [loading, set_loading] = useState(false);
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({});
    const [names, setNames] = useState([]);
    const [emails, setEmails] = useState([]);
    const [roles, setRoles] = useState([]);
    const [reloadUser, setReloadUser] = useState(0);

    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    }, {
        title: 'Nom',
        dataIndex: 'email',
        key: 'email',
        render: (value, record) => (<UserItemName record={record} />),
    }, {
        title: 'Rôle',
        dataIndex: 'roles',
        key: 'roles',
        render: roles => <UserItemRoles roles={roles} />,
    }, {
        title: 'Actif',
        dataIndex: 'active',
        key: 'active',
        render: actif => <UserItemActivation actif={actif}/>,
    }, {
        title: 'Admin',
        dataIndex: 'isAdmin',
        key: 'isAdmin',
        render: isAdmin => <UserItemIsAdmin isAdmin={isAdmin}/>,
    }, {
        title: 'Date d\'activation',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: value => <UserItemDate date={value} />
    }, {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        className: 'col_action',
        render: (value, record) => <UserItemActions onUpdate={() => edit(record.uuid)} onDelete={(e) => del(e, record.id)}/>,
    }]

    // create ---------------------------------------------------------
    const create = () => {
        history.push('/user/');
    }

    // edit -----------------------------------------------------------
    const edit = (uuid) => {
        history.push('/user/' + uuid);
    }

    // delete -----------------------------------------------------------
    const del = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        confirm({
            title: 'Confirmer la suppression ?',
            content: 'La suppression est définitive',
            onOk : async () =>
                userService.del(id)
                    .then(() => setReloadUser(reloadUser + 1))
                    .catch(error => {
                        message.error('Une erreur est survenue lors de la suppression');
                        console.log(error);
                    })
        
            ,
            onCancel : () => {},
        });
    }

    // updateFilters ----------------------------------------------
    function updateFilters(obj) {
        const filter = { ...filters, ...obj }
        for (let propName in filter) {
            if (filter[propName] &&  
                filter[propName].length === 0) {
                delete filter[propName];
            }
        }
        setFilters(filter);
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        set_loading(true);
        userService.list()
            .then(response => response.data)
            .then(fullList => {
                // setUsers(fullList.map(item => ({
                //     key: item.id,
                //     ...item,
                // })));
                setNames(fullList.map(user => <Option key={user.id} value={user.username}>{user.username}</Option>))
                setEmails(fullList.map(user => <Option key={user.id} value={user.email}>{user.email}</Option>))
            })
            .catch(error => {
                message.error('Une erreur est survenue lors du chargement des utilisateurs');
                console.log(error);
            })
        roleService.list()
            .then(response => response.data)
            .then(list => {
                setRoles(list.map(role => <Option key={role.uuid} value={role.uuid}>{role.name}</Option>))
            })
            .catch(error => {
                message.error('Une erreur est survenue lors du chargement des roles');
                console.log(error);
            })
        set_loading(false);
    }, [])

    useEffect(() => {
        userService.list(filters)
            .then(response => response.data)
            .then(filteredList => filteredList.map(item => ({
                key: item.id,
                ...item,
            })))
            .then(filteredList => setUsers(filteredList))
            .catch(error => {
                message.error('Une erreur est survenue lors du chargement des utilisateurs');
                console.log(error);
            })
    }, [filters, reloadUser])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">

        <div className="header">
            <span className="title">Utilisateurs</span>
            <span className="subtitle">Gestion des utilisateurs et des rôles</span>
        </div>

        <div className="data_list_toolbar">
            <div className="search_filters">
                <SearchFilters
                    label={'Nom'}
                    placeholder={'Search by name'}
                    options={names}
                    onchange={(val) => updateFilters({ username : val })}
                    />
                <SearchFilters
                    label={'Email'}
                    placeholder={'Search by email'}
                    options={emails}
                    onchange={(val) => updateFilters({ email : val })}
                    />
                <SearchFilters
                    label={'Rôles'}
                    placeholder={'Search by role'}
                    options={roles}
                    onchange={(val) => updateFilters({ role : val })}
                    />
            </div>
            <div className="action_toolbar">
                <Button onClick={() => create()}>Créer</Button>
            </div>
        </div>

        <div className="data_list">
            <Table 
                columns={columns} 
                dataSource={users} 
                rowKey="id"
                loading={loading}
                className="common_list"
                />
        </div>

        </div>
    );
}