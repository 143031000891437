import React, { useEffect, useState } from "react";
import UserFormSource from "./UserFormSource";

export default function UserFormSources({rights, sources, selections, onChangeRange, onChangeSelection}) {

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(sources
            .map(source =>  {
                const right = rights?.find(right => right?.source === source.uuid) || {};
                return ({
                    key: source.uuid,
                    source: source,
                    right: {...right}, // clone to be take into account by useEffect
                    selections: selections[source.uuid] || {},
                })
            }))
    }, [sources, rights, selections])

    return (
        <div className="user-sources-form">
            <table className="user-sources-table">
                <thead>
                    <tr className="user-sources-header">
                        <td rowSpan={3}></td>
                        <td rowSpan={3} className="right-level-1">Classic</td>
                        <td colSpan={2} className="right-level-1">Live</td>
                        <td colSpan={4} className="right-level-1">Post</td>
                        <td rowSpan={3} className="right-level-1">Web</td>
                    </tr>
                    <tr className="user-sources-header">
                        {/* <td></td> */}
                        {/* <td></td> */}
                        <td rowSpan={2} className="right-level-2">Full</td>
                        <td rowSpan={2} className="right-level-2">Sectional</td>
                        <td colSpan={2} className="right-level-2">Full</td>
                        <td colSpan={2} className="right-level-2">Sectional</td>
                        {/* <td></td> */}
                    </tr>
                    <tr className="user-sources-header">
                        {/* <td></td> */}
                        {/* <td></td> */}
                        {/* <td></td> */}
                        {/* <td></td> */}
                        <td className="right-level-3">DataRaw</td>
                        <td className="right-level-3">MEC</td>
                        <td className="right-level-3">DataRaw</td>
                        <td className="right-level-3">MEC</td>
                        {/* <td></td> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map(item => 
                                <UserFormSource
                                    key={item.key}
                                    source={item.source}
                                    right={item.right}
                                    selections={item.selections}
                                    onChangeRange={(rightKey, start, stop) => onChangeRange(item.source.uuid, rightKey, start, stop)}
                                    onChangeSelection={(rightKey, selected) => onChangeSelection(item.source.uuid, rightKey, selected)}
                                    />
                            )
                    }
                </tbody>
            </table>
        </div>
    );
}
