import axios from "axios";
import authHeader from "./auth-header";

async function injectFile(file) {
    return Promise.resolve()
        .then(() => new FormData())
        .then(fmData => { fmData.append("json_file", file); return fmData; })
        .then(fmData => axios.post(
            `${window.APP_SERVER_URL}/api/v2/update/MANUAL`,
            fmData,
            {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                    // 'content-type': 'multipart/form-data',
                },
            }
        ))
        .then(response => response.data.value);
}

// default export ---------------------------------------------------
const meeting = {
    injectFile,
};

export default meeting;